import { Button } from "@cellectenergy/cellect-react-components";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { StoryblokComponent } from "@storyblok/react";
import Image from "next/image";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@cellectenergy/cellect-react-components";
import CarouselBottomSelector from "@/components/ui/carousel/CarouselBottomSelector";

export const LandingBody = ({ blok }: any) => {
  const theme = useTheme();
  const { button, content, image } = blok;
  const isSx = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container columnSpacing={8} rowSpacing={3}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            width: "auto",
            height: { xs: "224px", sm: "448px", lg: "778px" },
            position: "relative",
            borderRadius: "1.5rem",
            overflow: "hidden",
          }}
        >
          <Image
            src={image.filename}
            alt={image.alt}
            layout="fill"
            objectFit="cover"
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        position={"relative"}
      >
        {isSx ? (
          <CarouselBottomSelector content={content} />
        ) : (
          <Box display={"flex"} flexDirection={"column"} gap={2} pb={8}>
            {content?.map((nestedBlok: any) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </Box>
        )}
        {button && (
          <Button
            // onClick={redirect}
            endIcon={<ArrowForwardIcon />}
            sx={{
              width: "fit-content",
              position: "absolute",
              bottom: 0,
              left: { xs: "45%", md: "4rem" },
              backgroundColor:
                theme.palette.common.corporateColors.secondary.turquoise,
            }}
          >
            {button}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

//

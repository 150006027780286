"use client";

import { useState } from "react";
import { Card, Typography, IconButton, Tooltip, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@cellectenergy/cellect-react-components";
import Image from "next/image";

export const AvatarCard = ({ blok }: any) => {
  const { name, email, image, position } = blok;
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);

    // Reset tooltip after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        gap: { xs: 2, md: 3 },
        width: { xs: "100%", md: "387px" },
        maxWidth: "387px",
        bgcolor: theme.palette.common.corporateColors.primary.midNightBlue,
        border: `3px solid ${theme.palette.common.corporateColors.secondary.turquoise}`,
        borderRadius: 3,
      }}
    >
      {/* Avatar */}
      <Box
        sx={{
          position: "relative",
          borderRadius: "0.5rem",
          width: { xs: "3.75rem", md: "7.5rem" },
          height: { xs: "3.75rem", md: "7.5rem" },
          overflow: "hidden",
        }}
      >
        <Image
          src={image.filename || "/default-avatar.png"} // Usa una imagen por defecto si no hay avatarUrl
          alt={name}
          layout="fill"
          objectFit="cover"
        />
      </Box>

      {/* Info */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          padding: 0,
        }}
      >
        <Typography variant="title-medium" fontWeight={500}>
          {name}
        </Typography>
        <Typography variant="title-large" fontWeight={500}>
          {position}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body-large" color="text.secondary">
            {email}
          </Typography>

          {/* Botón Copiar */}
          <Tooltip title={copied ? "Copiado!" : "Copiar"} arrow>
            <IconButton onClick={handleCopy} sx={{ marginLeft: 0.5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </Card>
  );
};

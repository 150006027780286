import { Button } from "@cellectenergy/cellect-react-components";
import { Box, Grid, Typography } from "@mui/material";
import { storyblokEditable } from "@storyblok/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Image from "next/image";
import { useTheme } from "@cellectenergy/cellect-react-components";
import { useRouter } from "next/router";

const LandingHeader = ({ blok }: any) => {
  const {
    title,
    description,
    image,
    landing_section,
    button_text,
    internal_link,
    external_link,
  } = blok;
  const theme = useTheme();
  const router = useRouter();

  const redirect = () => {
    if (internal_link) {
      router.push(internal_link);
    } else if (external_link) {
      window.open(external_link, "_blank");
    }
  };

  return (
    <div {...storyblokEditable(blok)}>
      <Grid container height={"100%"} columnSpacing={6} rowSpacing={3}>
        <Grid
          item
          xs={12}
          md={6}
          rowGap={{ xs: 2, md: 3 }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          order={{ xs: 2, md: 1 }}
        >
          <Typography variant="headline-small">{landing_section}</Typography>
          <Typography variant="display-medium" fontWeight={500}>
            {title}
          </Typography>
          <Typography variant="title-large">{description}</Typography>
          <Button
            onClick={redirect}
            endIcon={<ArrowForwardIcon />}
            sx={{
              width: "fit-content",
              backgroundColor:
                theme.palette.common.corporateColors.secondary.turquoise,
            }}
          >
            {button_text}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Box
            sx={{
              width: "auto",
              height: { xs: "224px", sm: "448px", lg: "592px" },
              position: "relative",
              borderRadius: "1.5rem",
              overflow: "hidden",
            }}
          >
            <Image
              src={image.filename}
              alt={title}
              layout="fill"
              objectFit="cover"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingHeader;

import { Button, styled } from "@mui/material";

export const ButtonMuiStyled = styled(Button)`
  text-transform: none;
  border-radius: 100px;
  gap: 8px;

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.common.neutral?.[80]};
    color: ${({ theme }) => theme.palette.common.neutral?.[70]};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.common.corporateColors?.secondary.turquoise};
    opacity: 0.8 !important;
  }
`;

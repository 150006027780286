import Image from "next/image";
import {
  ContactCard,
  CustomForm,
  ImageWrapper,
} from "../contactUs/contactUs.styles";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { GooglePolicy } from "@/components/ui/googlePolicy";
import { CustomCheckbox } from "@/components/ui/checkbox";
import { InputText } from "@/components/input";
import { ButtonMui } from "@/components/ui";
import { SendFormWithCaptcha } from "@/helpers/sendFormWithCaptcha";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { handleSubmitChecks } from "@/components/contact/start/helper";
import { sendContact } from "@/data";
import { ROUTES } from "@/constants/routes";
import { useTranslation } from "react-i18next";

export const Form = () => {
  const theme = useTheme();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();
  const { t } = useTranslation("global");

  const [loading, setLoading] = useState(false);
  const [submitedState, setSubmitedState] = useState("");
  const [checked, setChecked] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    country: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState({
    email: false,
    message: false,
  });

  const handleOnChange = () => {
    setChecked(!checked);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    handleSubmitChecks({
      customerInfo: formValues,
      setError,
      setAlertState: setSubmitedState,
      setIsLoading: setLoading,
      setCustomerInfo: setFormValues,
      SendFormWithCaptcha,
      executeRecaptcha,
      sendContact,
      router,
      t,
      error,
    });
  };

  useEffect(() => {
    if (submitedState === "success") {
      const newWindow = window.open(ROUTES.thankYouContact, "_blank");
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        window.location.href = ROUTES.thankYouContact;
      }
    }
  }, [submitedState]);

  return (
    <ContactCard>
      <ImageWrapper>
        <Image
          src={"/assets/contactUs/questionUs.svg"}
          alt="Contact us"
          width={106}
          height={106}
          loading="lazy"
        />
      </ImageWrapper>
      <Typography
        fontWeight={500}
        variant="headline-medium"
        color={theme.palette.common.corporateColors.primary.midNightBlue}
      >
        {t("contactUs.form.title")}
      </Typography>
      <CustomForm onSubmit={handleFormSubmit}>
        <InputText
          textcolor={theme.palette.common.corporateColors.primary.midNightBlue}
          borderfocuscolor={
            theme.palette.common.corporateColors.secondary.tiaraGray
          }
          type="text"
          variant="outlined"
          name="Name"
          required
          autoComplete="off"
          value={formValues.firstName}
          placeholder={t("contactUs.form.firstName").toString() + "*"}
          onChange={(e) =>
            setFormValues({ ...formValues, firstName: e.target.value })
          }
        />

        <InputText
          textcolor={theme.palette.common.corporateColors.primary.midNightBlue}
          sx={{
            //helpertext color
            "& .MuiFormHelperText-root": {
              color: theme.palette.error.main,
            },
          }}
          type="email"
          required
          variant="outlined"
          name="Email"
          helperText={error.email ? "wrong email format" : ""}
          autoComplete="off"
          value={formValues.email}
          placeholder={t("contactUs.form.email").toString() + "*"}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
        />

        <InputText
          placeholder={t(`support.startConversation.form.message`) + "*" || ""}
          textcolor={theme.palette.common.corporateColors.primary.midNightBlue}
          required
          multiline
          variant="outlined"
          autoComplete="off"
          rows={3}
          onChange={(e) =>
            setFormValues({ ...formValues, message: e.target.value })
          }
          value={formValues.message}
          sx={{
            "& .MuiInputBase-input": {
              padding: "0",
            },
          }}
        />

        <GooglePolicy
          sx={{
            color: theme.palette.common.corporateColors.primary.midNightBlue,
          }}
        />

        <label
          htmlFor="form-suscriptors"
          style={{
            display: "flex",
            alignItems: "center",
            color: `${theme.palette.common.corporateColors.primary.midNightBlue} !important`,
          }}
        >
          <CustomCheckbox
            id="form-suscriptors"
            text={"footer.terms"}
            textLink={"footer.links.link1"}
            link={"/privacy"}
            onChange={handleOnChange}
            checked={checked}
            sx={{
              color: `${theme.palette.common.corporateColors.primary.midNightBlue} !important`,
              "&.Mui-checked": {
                color: theme.palette.common.corporateColors.secondary.turquoise,
              },
              padding: "0.5rem 0.5rem 0.5rem 0rem",
            }}
          />
        </label>

        <ButtonMui
          variant="contained"
          disabled={!checked}
          type="submit"
          sx={{
            width: "fit-content",
            color: theme.palette.common.corporateColors.secondary.turquoise,
            backgroundColor:
              theme.palette.common.corporateColors.primary.midNightBlue,
            "&:hover": {
              backgroundColor:
                theme.palette.common.corporateColors.primary.midNightBlue,
              opacity: 0.8,
            },
          }}
          endIcon={
            loading ? (
              <CircularProgress
                color="info"
                size={20}
                sx={{
                  color:
                    theme.palette.common.corporateColors.secondary.turquoise,
                }}
              />
            ) : submitedState === "success" ? (
              <CheckCircleOutlineIcon
                color="success"
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            ) : submitedState === "error" ? (
              <ErrorOutlineIcon
                color="error"
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            ) : null
          }
        >
          {t("contactUs.form.send")}
        </ButtonMui>
      </CustomForm>
    </ContactCard>
  );
};

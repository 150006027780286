import { Button } from "@cellectenergy/cellect-react-components";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";
import Image from "next/image";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

export const Divider = ({ blok }: any) => {
  const { title, description, button, image } = blok;

  const theme = useTheme();

  return (
    <Grid
      container
      bgcolor={theme.palette.common.corporateColors.secondary.turquoise}
      padding={{ xs: "1.5rem 1rem", md: "4rem 3rem" }}
      zIndex={1}
      columnSpacing={{ xs: 0, md: 2 }}
      gap={{ xs: 3, md: 0 }}
    >
      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
        <Box
          color={theme.palette.common.corporateColors.primary.midNightBlue}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          <Typography variant="display-small" fontWeight={500}>
            {title}
          </Typography>
          <Typography variant="headline-small">{description}</Typography>
          <Button
            // onClick={redirect}
            endIcon={<VerticalAlignBottomIcon />}
            sx={{
              width: "fit-content",
              backgroundColor:
                theme.palette.common.corporateColors.primary.midNightBlue,
              color: theme.palette.common.corporateColors.secondary.turquoise,
            }}
          >
            {button}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
        <Box
          sx={{
            width: "auto",
            height: { xs: "224px", md: "419px" },
            position: "relative",
            borderRadius: "1.5rem",
            overflow: "hidden",
          }}
        >
          <Image
            src={image.filename}
            alt={image.alt}
            layout="fill"
            objectFit="cover"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

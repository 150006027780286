"use client";
import { FC } from "react";
import Head from "next/head";

import styled from "styled-components";
import dynamic from "next/dynamic";

const NavBar = dynamic(() => import("../components/navbar/NavBar"), {
  ssr: true,
});

const Footer = dynamic(() => import("../components/ui/footer/Footer"), {
  ssr: false,
});

type HomeLayoutProps = {
  children: React.ReactNode;
  imgUrl?: string;
  title?: string;
  description?: string;
  className?: string;
  navbarTheme?: string;
  bgTheme?: string;
  url?: string;
  spacerColor?: string;
  spacerDisplay?: string;
};

const MainLayoutContainer = styled.div<{ bgTheme?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ bgTheme }) => (bgTheme ? bgTheme : "")};
  flex: 1;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainLayout: FC<HomeLayoutProps> = ({
  children,
  imgUrl,
  title,
  description,
  className,
  bgTheme,
  url,
}) => {
  return (
    <PageContainer>
      <Head>
        <title key="title" property="og:title">
          {title || "Cellect Energy"}
        </title>
        <meta
          key="description"
          property="og:description"
          name="description"
          content={
            description ||
            "Cellect is a platform that helps you to optimize your business"
          }
        />
        <meta
          key="image"
          property="og:image"
          content={
            imgUrl ||
            `${process.env.NEXT_PUBLIC_CORPORATE_BASE_PATH}/assets/logo-dark-meta.png`
          }
        />
        <meta
          key="url"
          property="og:url"
          content={url ? url : `${process.env.NEXT_PUBLIC_CORPORATE_BASE_PATH}`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/cellect.ico" />
      </Head>
      <MainLayoutContainer bgTheme={bgTheme} className={className}>
        <NavBar />
        {children}
      </MainLayoutContainer>
      <Footer />
    </PageContainer>
  );
};

export default MainLayout;

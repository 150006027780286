import AutoSlider from "@/storyblokComponents/AutoSlider";
import { AvatarCard } from "@/storyblokComponents/AvatarCard";
import { Description } from "@/storyblokComponents/Description";
import { Divider } from "@/storyblokComponents/Divider";
import { Faq } from "@/storyblokComponents/Faq";
import { FaqItem } from "@/storyblokComponents/FaqItem";
import { LandingBody } from "@/storyblokComponents/LandingBody";
import { LandingFormSection } from "@/storyblokComponents/LandingFormSection";
import LandingHeader from "@/storyblokComponents/LandingHeader";
import Page from "@/storyblokComponents/Page";
import { Title } from "@/storyblokComponents/Title";
import { TitleAndDescription } from "@/storyblokComponents/TitleAndDescription";
import { UnorderList } from "@/storyblokComponents/UnorderList";

import {
  apiPlugin,
  storyblokInit,
  getStoryblokApi,
} from "@storyblok/react/rsc";

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_TOKEN,
  use: [apiPlugin],
  components: {
    page: Page,
    landing_header: LandingHeader,
    auto_slider: AutoSlider,
    landing_body: LandingBody,
    title: Title,
    description: Description,
    unorder_list: UnorderList,
    divider: Divider,
    landing_form_section: LandingFormSection,
    avatar_card: AvatarCard,
    faq: Faq,
    faq_item: FaqItem,
    title_and_description: TitleAndDescription,
  },
});

export { getStoryblokApi };

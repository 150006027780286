import { Typography } from "@mui/material";

export const Description = ({ blok }: any) => {
  const { description } = blok;
  return (
    <Typography pb={2} variant="headline-small">
      {description}
    </Typography>
  );
};

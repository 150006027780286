import { Form } from "@/components/home/form/Form";
import { Box, Grid, Typography } from "@mui/material";
import { StoryblokComponent } from "@storyblok/react";

export const LandingFormSection = ({ blok }: any) => {
  const { title, description, avatar_card } = blok;

  console.log("content", blok);

  return (
    <Grid container rowSpacing={4} pl={{ xs: 0, md: 4 }} pr={{ xs: 0, md: 4 }}>
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="display-small">{title}</Typography>
          <Typography variant="headline-small">{description}</Typography>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {avatar_card?.map((nestedBlok: any) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Form />
      </Grid>
    </Grid>
  );
};

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from "swiper";
SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Image from "next/image";

import { useTheme } from "@cellectenergy/cellect-react-components";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Suspense, useCallback, useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { StoryblokComponent } from "@storyblok/react";

const CarouselBottomSelector = ({ content }: any) => {
  const theme = useTheme();

  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const [selectedDot, setSelectedDot] = useState<number>();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      mb={8}
    >
      <Box
        display={"flex"}
        gap={"2rem"}
        alignItems={"center"}
        justifyContent={"center"}
        width={{ xs: "100%", md: "auto" }}
      >
        <Box
          display={{
            xs: "none",
            lg: "flex",
          }}
        >
          <Suspense fallback={null}>
            <KeyboardArrowLeftIcon
              sx={{
                color: theme.palette.common.corporateColors.secondary.turquoise,
                cursor: "pointer",
              }}
              onClick={handlePrevious}
            />
          </Suspense>
        </Box>
        <Swiper
          onSwiper={setSwiperRef}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{
            type: "custom",
            renderCustom: function (swiper, current, total) {
              setSelectedDot(current % 3);
              return "";
            },
          }}
          modules={[EffectCoverflow, Pagination]}
          className="swiper_container"
          initialSlide={1}
          breakpoints={{
            0: {
              coverflowEffect: {
                rotate: 0,
              },
            },
          }}
        >
          {content.map((nestedBlok: any, index: number) => {
            return (
              <SwiperSlide key={index} className={"versatile-slide"}>
                <Box height={"100%"} width={"100%"}>
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Box
          display={{
            xs: "none",
            lg: "flex",
          }}
        >
          <Suspense fallback={null}>
            <KeyboardArrowRightIcon
              sx={{
                color: theme.palette.common.corporateColors.secondary.turquoise,
                cursor: "pointer",
              }}
              onClick={handleNext}
            />
          </Suspense>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        {Array.from({ length: 3 }).map((_, i) => (
          <span
            key={i}
            style={{ cursor: "pointer" }}
            onClick={() => {
              swiperRef?.slideTo(i);
              setSelectedDot(i);
            }}
            className={
              "swiper-pagination-bullet" +
              (selectedDot === i ? " swiper-pagination-bullet-active" : "")
            }
          ></span>
        ))}
      </Box>
    </Box>
  );
};

export default CarouselBottomSelector;

import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const UnorderList = ({ blok }: any) => {
  const { title, list } = blok;

  const convertedList = list.split(",").map((item: string) => item.trim());

  return (
    <Box>
      <Typography variant="headline-medium">{title}</Typography>

      {convertedList.map((item: string) => (
        <Box display="flex" alignItems="center" key={item} gap={2}>
          <CheckIcon />
          <Typography variant="headline-small">{item}</Typography>
        </Box>
      ))}
    </Box>
  );
};

"use client";

import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

export const FaqItem = ({ blok, index }: any) => {
  const { title, description } = blok;
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (index: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false);
    };
  return (
    <motion.div
      key={index}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      <Accordion
        expanded={expanded === index}
        onChange={handleChange(index)}
        sx={{
          backgroundColor: "transparent",
          color: "white",
          backgroundImage: "none",
          boxShadow: "none",
          borderBottom: "1.5px solid #EBECE6",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="title-large">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body-large">{description}</Typography>
        </AccordionDetails>
      </Accordion>
    </motion.div>
  );
};

import MainLayout from "@/layouts/MainLayout";
import { Box } from "@mui/material";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const Page = ({ blok }: any) => {
  if (!blok) {
    return <div>Error: No data received for this page.</div>;
  }

  return (
    <MainLayout
      title="About | Cellect Energy"
      description="Our platform offers a universal solution for energy storage asset managers, providing a standard API for remote control which eliminates integration needs."
      navbarTheme="dark"
      {...storyblokEditable(blok)}
    >
      <Box
        padding={{ xs: "6rem 1rem", md: "7.5rem 2rem" }}
        display={"flex"}
        flexDirection={"column"}
        gap={{ xs: "5rem", md: "7.5rem" }}
        maxWidth={{ lg: "1440px" }}
        margin={{ lg: "0 auto" }}
      >
        {blok.body?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </Box>
    </MainLayout>
  );
};

export default Page;

import { FC, useState } from "react";
import { useTheme } from "@cellectenergy/cellect-react-components";
import { Box, Typography } from "@mui/material";

interface Props {
  selecteditem?: number;
}

const Partners = [
  { id: 1, name: "afk", url: "https://arendalsfossekompani.no/en" },
  { id: 2, name: "bves", url: "https://www.bves.de/" },
  { id: 3, name: "volue", url: "https://www.volue.com" },
  {
    id: 10,
    name: "greenFlexibility",
    url: "https://www.green-flexibility.com/",
  },
  { id: 4, name: "upc", url: "https://www.upc.edu/en" },
  { id: 6, name: "aepibal", url: "https://aepibal.org/" },
  { id: 9, name: "weidmuller", url: "https://www.weidmueller.com/" },
  { id: 8, name: "elli", url: "https://www.elli.eco/en/home " },
];

const AutoSlider: FC<Props> = ({ blok }: any) => {
  const { slider_title } = blok;

  const theme = useTheme();
  const [isStopped, setIsStopped] = useState(false);

  const repeatCount = 2;
  const arrayPartners = Array(repeatCount).fill(Partners).flat();

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={6}>
      <Box>
        <Typography variant="headline-medium" fontWeight={500}>
          {slider_title}
        </Typography>
      </Box>
      <Box
        className="slider"
        style={{
          backgroundColor:
            theme.palette.common.corporateColors.primary.midNightBlue,
        }}
        onMouseEnter={() => setIsStopped(true)}
        onMouseLeave={() => setIsStopped(false)}
      >
        <Box className={`slide-track${isStopped ? " stopped" : ""}`}>
          {arrayPartners.map((partner, index) => (
            <Box
              className="slide"
              key={index}
              onClick={() => window.open(partner.url, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                className="slideImg"
                style={{
                  transition: "all 1s ease-in-out",
                  width: "320px",
                  height: " 45px",
                }}
                src={`./assets/functioning/partners/partner${partner.id}.svg`}
                alt={partner.name}
                loading="lazy"
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoSlider;

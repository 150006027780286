import { Box, Typography } from "@mui/material";

export const TitleAndDescription = ({ blok }: any) => {
  const { title, description } = blok;

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Typography variant="headline-medium" fontWeight={500}>
        {title}
      </Typography>
      <Typography variant="headline-small">{description}</Typography>
    </Box>
  );
};

"use client";

import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { StoryblokComponent } from "@storyblok/react";

const faqData = [
  {
    question: "What is the Cellect Platform?",
    answer:
      "The Cellect software provides an advanced platform to effectively monitor, analyze and optimize energy storage systems of all manufacturers. This cloud-based solution can be accessed using any web browser from any location without the need for installation or setup. The user-friendly interface gives users access to complex information in a visual and easy-to-understand manner. Our technical team is available to provide comprehensive support for the connection process.",
  },
  {
    question: "Who can use the Energy Manager application?",
    answer:
      "The Energy Manager application can be used by businesses, homeowners, and energy professionals.",
  },
  {
    question: "How do I modify, delete or add a new account?",
    answer:
      "You can manage your account settings from the dashboard under the 'Account' section.",
  },
  {
    question: "What devices and platforms does the application support?",
    answer:
      "The application is compatible with Windows, macOS, iOS, and Android devices.",
  },
  {
    question: "How can I change the site settings?",
    answer:
      "You can modify site settings in the 'Settings' tab within your user profile.",
  },
];

export const Faq = ({ blok }: any) => {
  const { title, faq_container } = blok;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      pl={{ xs: 0, md: 15 }}
      pr={{ xs: 0, md: 15 }}
    >
      <Typography variant="display-small" fontWeight={500}>
        {title}
      </Typography>
      <Box>
        {faq_container.map((nestedBlok: any, index: number) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            index={index}
          />
        ))}
      </Box>
    </Box>
  );
};
